<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">权限名称 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入权限名称" v-model="data_form.name" name="name">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">权限标题 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入权限标题" v-model="data_form.title" name="title">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">附加条件 :</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入附加条件" v-model="data_form.condition" name="condition">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">所属栏目 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">

                        <v-treeview
                                hoverable
                                dense
                                selectable
                                selected-color="red"
                                selection-type="independent"
                                item-key="id"
                                :items="menu_list"
                                :value="menuSelectNode"
                                @input="menuTreeSelect"
                        ></v-treeview>
                        <input type="hidden" class="form-control" placeholder="请选择菜单图片" v-model="data_form.menu_id" name="menu_id" ref="menu_id_input">
                        <span class="form-text text-muted">请选择权限所属栏目</span>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    import {addRule} from '@/api/rule';
    import {getMenuList} from '@/api/menu';

    export default {
        name: "rule_add",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {
            var _this = this;
            getMenuList({}).then(function(res){
                if(res){
                    _this.menu_list = res.response;
                }
            });
        },
        components :{

        },
        data() {
            return {
                data_form:{
                    name: '',
                    title: '',
                    condition: '',
                    menu_id: ''
                },
                menu_list: [],
                menuSelectNode: [0]
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    name: {
                        validators: {
                            notEmpty: {
                                message: "权限名称不能为空"
                            }
                        }
                    },
                    title: {
                        validators: {
                            notEmpty: {
                                message: "权限标题不能为空"
                            }
                        }
                    },
                    menu_id: {
                        validators: {
                            notEmpty: {
                                message: "权限所属栏目不能为空"
                            }
                        }
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                addRule(_this.data_form).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            menuTreeSelect(array) {
                if(array && array.length > 0) {
                    var index = array.length - 1;
                    this.menuSelectNode = [array[index]];
                    this.data_form.menu_id = this.menuSelectNode[0];
                }
                //this.menuSelectNode = [];
                //var index = array.length - 1;
                //this.menuSelectNode.push(array[index]);
                //this.data_form.menu_id = this.menuSelectNode[0];
                //this.$refs["menu_id_input"].parentElement.lastChild.remove();
            }
        }
    }
</script>
